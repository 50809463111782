$primary-main-colour: #F5DAE1;
$primary-light-colour: #F6EFEF;
$primary-dark-colour: #E50079;
$secondary-main-colour: #F2F0EF;
$secondary-light-colour: #F8F8F8;
$secondary-dark-colour: #353A4A;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", "Arial", sans-serif;
  color: $secondary-dark-colour;
}

.sectionAnchor {
  position: absolute;
  margin-top: calc(-3em - 66px);
}

#top-section {
  margin: 0;
  top: 0;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: $secondary-dark-colour;
}

p {
  max-width: 60ch;
  margin: 0 auto 1em;
  line-height: 1.55;
}

svg path {
  fill: $secondary-dark-colour;
}

/* Header */
header {
  .navMenu {
    min-height: 66px;
    display: flex;
    justify-content: space-between;
  }

  .logoText {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.2;

    a {
      text-decoration: none;
      color: $secondary-dark-colour;
    }
  }
}

.MuiDrawer-root {
  .MuiButtonBase-root {
    height: 3.5em;
  }

  .menuLink {
    font-size: .85em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: center;
  }

  .closeIcon {
    float: right;
    height: 64px;
    width: 64px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 99;
  }
}

/* Content */
main {
  padding-top: 64px;
}

/* Sections */
section {
  .sectionHeading,
  .sectionSubheading {
    font-weight: 700;
    letter-spacing: .05em;
    text-align: center;
  }

  .sectionHeading {
    font-size: 1.6em;
  }

  .sectionSubheading {
    font-size: 1.2em;
    margin: 2.5em 0 1.5em;
  }

  .accordionHeading {
    font-size: 1em;
  }
}

.section {
  &:not(:first-child) {
    padding: 3.5em 0;
  }
  &:nth-of-type(even) {
    background-color: $secondary-light-colour;
  }
  &:nth-of-type(odd) {
    background-color: #fff;
  }
}

.fade-in {
  opacity: 0;
  transform: translate(0, 50%);
  visibility: hidden;
  transition: opacity 1000ms ease-out, transform 1000ms ease-out;
  will-change: opacity, visibility;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* TOPHEADING */
#topHeading {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .parallaxBG {
    position: fixed;
    width: 100%;
    height: 75vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .bokeh {
    width: 100%;
    height: 75vh;
    // position: absolute;
    position: fixed;
    overflow: hidden;
    z-index: -1;

    $bokehCount: 50;
    $bokehBaseSize: 1rem;
    $bokehBaseOpacity: 0.45;
    $bokehBaseBrightness: 200;
    $bokehBaseDuration: 16s;
    $colorSet: (
      #ff8cc9,
      #ff9bd0,
      #ffb5d1,
      #F5DAE1
    );

    div {
      position: absolute;
      border-radius: 50%;
      animation-name: bokeh;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      
      @for $i from 1 through $bokehCount {
        &:nth-child(#{$i}) {
          $randomizedSize: random() + $bokehBaseSize;
          width: $randomizedSize;
          height: $randomizedSize;
          top: random(100) * 1%;
          left: random(100) * 1%;
          color: nth($colorSet, random(length($colorSet)));
          opacity: $bokehBaseOpacity + random(4) * 0.15;
          box-shadow: ($randomizedSize + 5rem) 0 (0.2rem + random()*0.5rem) currentColor;
          filter: brightness($bokehBaseBrightness + random(150)*1%);
          transform-origin:  (random(40)-20) * 1rem (random(40)-20) * 1rem;
          animation-duration: $bokehBaseDuration + random(30) * 1s;
        }
      }
    }
  }

  @keyframes bokeh {
    100% {transform: rotate(360deg);}
  }

  .textBg {
    background-color: rgba(255, 255, 255, .8);
    padding: 2em 1.5em;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  }

  h2,
  .subtitle {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.8em;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 1em;
    letter-spacing: .08em;
  }

  p {
    max-width: 280px;
    margin: 0 auto;
    line-height: 1.7;
  }
}

/* WORKS */
#works {
  .collections-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    margin-top: 3em;
  }

  .collections-masonry-grid-column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    > section > div {
      margin-bottom: 50px;
      border-radius: 0;

      .MuiCardHeader-root {
        padding-top: 1.5em;
        padding-bottom: 0;
      }

      .workThumb {
        &:hover {
          opacity: .9;
          transition: opacity .1s ease-in;
        }
      }
    }
  }

  .tags {
    padding: 1em 0 .3em;
    text-transform: uppercase;
    .MuiChip-root {
      background-color: $secondary-light-colour;
      height: 2.15em;
      margin: .55em .55em 0 0;
      font-size: .55em;
      font-weight: 700;
      letter-spacing: .05em;

      span {
        padding: 0 1em;
      }
    }
  }

  .cardAction {
    background-color: $primary-main-colour;
    justify-content: flex-end;
    
    .cardActionLink {
      margin: 0 1.5em 0 0;
      background-color: $primary-main-colour;
      color: $secondary-dark-colour;
      font-weight: 600;

      &:hover {
        filter: contrast(.9);
        transition: filter .1s ease-in;
      }
    }
  }
}

/* SKILLS */
#skills {
  .skillList {
    padding: 3em 0;
    
    .skillIcon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h6 {
        font-size: .8em;
        font-weight: 700;
        margin-top: .5em;
      }
    }
  }
}

/* ABOUT */
#about {
  h2 {
    margin-bottom: 2em;
  }

  .MuiPaper-root {
    max-width: 62ch;
    margin-left: auto;
    margin-right: auto;

    .MuiAccordionDetails-root {
      p {
        margin-bottom: 1em;
      }
    }
  }
}

/* CONTACT */
#contact {
  p {
    text-align: center;
  }
  .accountList {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .accountLink {
      margin: 1em 5px;
      
      .accTagLink {
        text-decoration: none;
        color: inherit;
        width: 64px;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
          filter: brightness(.3);
          transition: filter .2s ease-in;
        }

        .accountLabel {
          font-weight: 700;
          font-size: .55em;
          letter-spacing: .05em;
          margin-top: 5px;
        }
      }
    }
  }
  
  form {
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiFormControl-root {
      width: 95%;
      margin: .5em auto;
    }

    label.formTextField {
      color: red;
      .Mui-focused {
        color: green;
      }
    }

    button {
      font-weight: 600;
      font-size: .9em;
      letter-spacing: .05em;
      margin-top: 1.5em;
      margin-right: 1em;
      display: inline-block;
      width: fit-content;
      align-self: center;

      &:hover {
        background-color: $primary-main-colour;
        box-shadow: none;
        filter: contrast(.9);
        transition: all .1s ease-in;
      }
    }
  }
}

/* Footer */
footer {
  background-color: $secondary-dark-colour;
  color: rgba(255, 255, 255, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  div {
    text-transform: uppercase;
    font-size: .75em;
  }
}

/* Media Queries */
@media (min-width: 600px) {
  header {
    .logoText {
      font-Size: 1.25em;
    }
  }

  .MuiDrawer-root {
    .menuLink {
      font-size: 1em;
    }
  }

  #topHeading {
    height: 85vh;

    .textBg {
      padding: 4em 3em;
    }

    h2 {
      font-Size: 3.5em;
    }

    .subtitle {
      font-Size: 1.3em;
      margin-bottom: 40px;
    }

    p {
      max-width: 500px;
    }

    .parallaxBG,
    .bokeh {
      height: 85vh;
    }
  }

  #contact {
    form {
      .MuiFormControl-root {
        width: 450px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .section {
    &:not(:first-child) {
      padding: 5em 0;
    }
  }

  #topHeading {
    .textBg {
      padding: 5em 6em;
    }

    h2 {
      font-Size: 4em;
    }

    p {
      font-size: 1.1em;
    }
  }
}
